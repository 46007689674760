import React, { Component } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';

class PageLayout extends Component {
	render () {
    const {
      title,
      content
    } = this.props.postData;

    return (
      <Layout>
        <Seo post={this.props.postData} />
        <div className="max-w-screen-xl mx-auto">
          <div className="w-12/14 mx-auto py-12">
            <h1 className="headline-1 pb-6 text-black">{title}</h1>
            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </Layout>
    )
  }
}

PageLayout.propTypes = {
	postData: PropTypes.object,
}

PageLayout.defaultProps = {
	postData: ``,
}

export default PageLayout
